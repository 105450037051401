<template>
    <fieldset ref="generalForm" :disabled="occurrenceFinished" style="display: block">
        <div class="row-fluid mb-2 mt-4">
            <b>Dados Principais</b>
        </div>

        <div class="row">
            <div class="col-12 col-md-4 mb-3">
                <v-select
                        :class="[{ 'is-invalid ': $v.tipoBoletim.$error}, 'style-chooser']"
                        v-model="tipoBoletim"
                        required
                        :disabled="occurrenceFinished"
                        placeholder="Tipo do Boletim"
                        :options="[
                            'Prisão em Flagrante',
                            'Termo Circunstanciado',
                            'Informativo',
                            'Acidente de Trânsito'
                        ]"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-4">
                <div class="form-label-group">
                    <input type="text"  id="inputProtocolo" disabled v-model="protocolo" :class="[{ 'is-invalid ': $v.protocolo.$error}, 'form-control']" placeholder="Protocolo" required/>
                    <label for="inputProtocolo">Protocolo</label>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="form-label-group">
                    <input type="text" v-mask="'##/##/#### ##:##'" disabled id="inputDataHoraRegistro" v-model="dataRegistro" :class="[{ 'is-invalid ': $v.dataRegistro.$error, 'form-control': true }, 'form-control']" placeholder="Data e Hora do Registro" required/>
                    <label for="inputDataHoraRegistro">Data do Registro</label>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="form-label-group">
                    <input type="text" v-mask="'##/##/#### ##:##'" id="inputDataHoraFato" v-model="dataHoraFato" :class="[{ 'is-invalid ': $v.dataHoraFato.$error}, 'form-control']" placeholder="Data e Hora do Fato" required/>
                    <label for="inputDataHoraFato">Data do Fato</label>
                </div>
            </div>

        </div>

        <div class="row">

            <div class="col-12 mb-3 mb-md-0 col-md-6">
                <v-select
                        :class="[{ 'is-invalid ': $v.listaNaturezas.$error}, 'style-chooser']"
                        multiple
                        v-model="listaNaturezas"
                        required
                        :disabled="occurrenceFinished"
                        placeholder="Naturezas"
                        :options="natures"
                />
            </div>

            <div class="col">
                <v-select
                        :class="[{ 'is-invalid ': $v.tipoLocal.$error}, 'style-chooser']"
                        v-model="tipoLocal"
                        required
                        :disabled="occurrenceFinished"
                        placeholder="Tipo do Local"
                        :options="[
                            'Acampamento sem terra','Aeroporto, pista de pouso etc.','Aldeia indígena',
                            'Ambiente virtual (internet)',
                            'Área de extração de minério/garimpo',
                            'Área de fronteira',
                            'Área militar',
                            'Área rural',
                            'Assentamento rural',
                            'Associação/sindicato',
                            'Carro de transporte de valores',
                            'Carvoaria',
                            'Casa noturna',
                            'Cemitério',
                            'Em veículo',
                            'Embarcação',
                            'Estabelecimento comercial',
                            'Estabelecimento de ensino',
                            'Estabelecimento industrial',
                            'Estabelecimento prisional',
                            'Estabelecimentos de saúde',
                            'Estação de metrô/trem',
                            'Estacionamento',
                            'Estádio esportivo',
                            'Hotel, pousada, motel',
                            'Igreja/templo/mesquita',
                            'Imóvel abandonado',
                            'Instituição financeira',
                            'Madereira/serralheria',
                            'Obra/construção civil',
                            'ONG/instituição filantrópica',
                            'Parque de diversões',
                            'Posto de combustível',
                            'Praça/parque',
                            'Praia',
                            'Quilombo',
                            'Repartição pública',
                            'Reserva ambiental',
                            'Residência',
                            'Rio/lago/represa',
                            'Shopping',
                            'Supermercado',
                            'Terminal de ônibus',
                            'Terreno baldio',
                            'Transporte coletivo',
                            'Via urbana',
                            'Vegetação',
                            'Desconhecido',
                            'Outros',
                            'Agência/correspondente bancário',
                            'Fazenda/sítio',
                            'Barreira Policial']"
                />
            </div>

        </div>

        <div class="row-fluid mb-2 mt-4">
            <b>Local da Ocorrência</b>
        </div>

        <div class="row">
            <div class="col-6 col-md-4">
                <v-select
                        :class="[{ 'is-invalid ': $v.codIBGEMunicipio.$error}, 'style-chooser']"
                        v-model="codIBGEMunicipio"
                        :value="codIBGEMunicipio"
                        placeholder="Município"
                        :options="[
                            {city: 'Rio Branco', code: '1200401'},
                            {city: 'Brasiléia', code: '1200104'},
                            {city: 'Cruzeiro do Sul', code: '1200203'},
                            {city: 'Sena Madureira', code: '1200500'},
                            {city: 'Feijó', code: '1200302'},
                            {city: 'Acrelândia', code: '1200013'},
                            {city: 'Plácido de Castro', code: '1200385'},
                            {city: 'Bujari', code: '1200138'},
                            {city: 'Senador Guiomard', code: '1200450'},
                            {city: 'Tarauacá', code: '1200609'},
                            {city: 'Santa Rosa do Purus', code: '1200435'},
                            {city: 'Marechal Thaumaturgo', code: '1200351'},
                            {city: 'Assis Brasil', code: '1200054'},
                            {city: 'Mâncio Lima', code: '1200336'},   
                            {city: 'Capixaba', code: '1200179'},
                            {city: 'Epitaciolândia', code: '1200252'},
                            {city: 'Jordão', code: '1200328'},
                            {city: 'Manoel Urbano', code: '1200344'},
                            {city: 'Porto Acre', code: '1200807'},
                            {city: 'Xapuri', code: '1200708'},
                            {city: 'Rodrigues Alves', code: '1200427'},
                            {city: 'Porto Walter', code: '1200393'},
                            ]"
                        :reduce="city => city.code"
                        :disabled="occurrenceFinished"
                        label="city"
                />
            </div>

            <div class="col-6 col-md-4">
                <v-select
                        v-model="tipoVia"
                        class="style-chooser"
                        placeholder="Tipo da Via"
                        :options="[{label: 'Via Pública', code: 'VP'}, {label: 'Rodovia Estadual', code: 'RE'}, {label: 'Rodovia Federal', code: 'RF'}]"
                        :reduce="label => label.code"
                        :disabled="occurrenceFinished"
                        label="label"
                />
            </div>

            <div class="col-12 col-md-4 mt-3 mt-md-0">
                <div class="form-label-group">
                    <input id="inputBairro"
                           v-title-case
                           v-model="bairro"
                           type="text"
                           class="form-control" placeholder="Bairro" required/>
                    <label for="inputBairro">Bairro</label>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-6">
                <div class="form-label-group">
                    <input id="inputLogradouro"  v-model="logradouro" v-title-case type="text" class="form-control" placeholder="Logradouro" required/>
                    <label for="inputLogradouro">Logradouro</label>
                </div>
            </div>

            <div class="col-6 col-md-3">
                <div class="form-label-group">
                    <input id="inputNumero" v-model="numero" type="text" class="form-control" placeholder="Número" required/>
                    <label for="inputNumero">Número</label>
                </div>
            </div>

            <div class="col-12 col-md-3">
                <div class="form-label-group">
                    <input id="inputComplemento" v-model="complemento" v-title-case type="text" class="form-control" placeholder="Complemento" required/>
                    <label for="inputComplemento">Complemento</label>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col">
                <div class="form-label-group">
                    <input id="inputReferencia" v-model="referencia" v-title-case type="text" class="form-control" placeholder="Referência" required/>
                    <label for="inputReferencia">Referência</label>
                </div>
            </div>
        </div>


        <div class="row-fluid mb-2 mt-4">
            <b>Encaminhamento da Ocorrência</b>
        </div>

        <div class="row">
            <div class="col-6 col-md-4">
                <div class="form-label-group">
                    <input type="text" id="inputUnidadeEntrega" v-model="unidadeEntrega" v-upper-case class="form-control" placeholder="Unidade de Entrega"/>
                    <label for="inputUnidadeEntrega">Unidade de Entrega</label>
                </div>
            </div>

            <div class="col-6 col-md-8">
                <div class="form-label-group">
                    <input type="text" id="inputRecebedor" v-model="nomeRecebedor" v-title-case class="form-control" placeholder="Nome do Recebedor"/>
                    <label for="inputRecebedor">Nome do Recebedor</label>
                </div>
            </div>
        </div>

    </fieldset>
</template>
<script>
    import { mapFields } from 'vuex-map-fields';
    import { required } from 'vuelidate/lib/validators'
    import * as natures from '../../assets/json/natures.json'

    export default {
        data(){
            return{
                natures: JSON.parse(JSON.stringify(natures.data))
            }
        },
        computed: {
            ...mapFields('occurrences', [
                'occurrence.protocolo',
                'occurrence.dataHoraFato',
                'occurrence.dataRegistro',
                'occurrence.logradouro',
                'occurrence.numero',
                'occurrence.bairro',
                'occurrence.complemento',
                'occurrence.referencia',
                'occurrence.codIBGEMunicipio',
                'occurrence.listaNaturezas',
                'occurrence.tipoLocal',
                'occurrence.tipoBoletim',
                'occurrence.tipoVia',
                'occurrence.unidadeEntrega',
                'occurrence.nomeRecebedor',
            ]),
            complete() {
                return !this.$v.$invalid;
            },
            occurrenceFinished(){
                return this.$store.state.occurrences.occurrence.status === 'FI';
            }
        },
        validations: {
            protocolo: {
                required,
            },
            listaNaturezas: {
                required,
            },
            codIBGEMunicipio: {
                required,
            },
            dataHoraFato: {
                required,
            },
            dataRegistro: {
                required
            },
            tipoLocal: {
                required
            },
            tipoBoletim: {
                required
            }
        },
        watch: {
            complete: function (status) {
                this.$store.dispatch('occurrences/completeStep', {
                    status: status,
                    step: 'general'
                });
            }
        },
        created() {
            this.$store.dispatch('occurrences/completeStep', {
                status: !this.$v.$invalid,
                step: 'general'
            });
            this.$store.dispatch('occurrences/getOccurrence', {
                protocol: this.$route.params.protocol,
                index: 1,
                register: true
            });
            this.$v.$touch();
        }

    };

</script>