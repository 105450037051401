<template>
  <fieldset  :disabled="occurrenceFinished"  style="display: block">
    <div v-for="(involved, index) in listaEnvolvidos" v-bind:key="index">
      <div class="row-fluid mb-2 mt-4">
        <h4>
          Indivíduo {{ String.fromCharCode( 65 + index )  }}
          <button
                  @click.prevent="removeInvolved(index)"
                  type="button"
                  class="close float-none"
                  aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </h4>
      </div>

      <div class="row-fluid">
        <b>Dados Pessoais</b>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-md-4">
          <v-select
                  :class="{ 'is-invalid ': $v.listaEnvolvidos.$each.$iter[index].envolvimento.$error, 'style-chooser': true }"
                  v-model="involved.envolvimento"
                  @change="updateInvolved($event.target.value, 'envolvimento', index)"
                  placeholder="Envolvimento"
                  :options="['Autor', 'Vítima', 'Testemunha', 'Suspeito', 'Comunicante', 'Autor/Vítima', 'Menor Infrator', 'Condutor']"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-5">
          <div class="form-label-group">
            <input
                    :id="'inputCPF-' + index"
                    v-model="involved.cpf"
                    type="text"
                    v-mask="'###.###.###-##'"
                    class="form-control"
                    placeholder="CPF"
                    required
            />
            <label :for="'inputCPF-'+ index">CPF</label>
          </div>
        </div>

        <div class="col-6 col-md-4">
          <div class="form-label-group">
            <input
                    :id="'inputRG-' + index "
                    v-model="involved.rg"
                    type="text"
                    class="form-control"
                    placeholder="RG"
                    required
            />
            <label :for="'inputRG-' + index">RG</label>
          </div>
        </div>

        <div class="col-6 col-md-3">
          <div class="form-label-group">
            <input
                    :id="'inputRgEmissor-' + index"
                    v-model="involved.rg_emissor"
                    v-upper-case
                    type="text"
                    class="form-control"
                    placeholder="Órgão Emissor"
                    required
            />
            <label :for="'inputRgEmissor-' + index">Órgão Emissor</label>
          </div>
        </div>

        <div class="col-12 col-md-7">
          <v-select
                  :class="{ 'is-invalid ': $v.listaEnvolvidos.$each.$iter[index].nome.$error, 'style-chooser': true }"
                  v-model="involved.nome"
                  :value="involved.nome"
                  @input="selectCitizen"
                  @search="nome => fetchOptions(nome, index)"
                  v-title-case
                  placeholder="Nome Civil"
                  taggable
                  :selectOnTab="true"
                  :options="involvedSearch"
                  :reduce="item => item.id || item.nome"
                  :clearSearchOnBlur="() => onBlurSearch(index)"
                  label="nome"
          >
            <template slot="option" slot-scope="option">
              <b class="d-block">{{ titleCase(option.nome) }}</b>
              <small>
                <span v-if="option.nomeMae">
                  <span class="text-muted">Mãe:  {{ option.nomeMae }}</span>
                </span>
              </small>
            </template>
          </v-select>
        </div>

        <div class="col-6 col-md-3 mt-3 mt-md-0">
          <div class="form-label-group">
            <input
                    :id="'inputDataNascimento-' + index"
                    v-model="involved.dataNascimento"
                    type="text"
                    v-mask="'##/##/####'"
                    @keyup="calcIdade(index, $event)"
                    class="form-control"
                    placeholder="Data de Nascimento"
                    required
            />
            <label :for="'inputDataNascimento-' + index ">Data de Nascimento</label>
          </div>
        </div>

        <div class="col-6 col-md-2 mt-3 mt-md-0">
          <div class="form-label-group">
            <input
                    :id="'inputIdade-' + index"
                    v-model="involved.idade"
                    class="form-control"
                    type="text"
                    v-mask="'###'"
                    placeholder="Idade"
                    required
            />
            <label :for="'inputIdade-' + index ">Idade</label>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6 col-md-6">
          <v-select
              class="style-chooser"
              v-model="involved.identidade_genero"
              @change="updateInvolved($event.target.value, 'identidade_genero', index)"
              placeholder="Identidade de Gênero"
              :options="['Não Informado', 'Cisgênero', 'Transgênero', 'Não-binário']"
          />
        </div>

        <div class="col-6 col-md-6">
          <v-select
              class="style-chooser"
              v-model="involved.orientacao_sexual"
              @change="updateInvolved($event.target.value, 'orientacao_sexual', index)"
              placeholder="Orientação Sexual"
              :options="['Não Informado', 'Heterossexual', 'Homossexual', 'Bissexual', 'Pansexual']"
          />
        </div>

        <div class="col-12 mt-3">
          <div class="form-label-group">
            <input
                :id="'inputNomeSocial-' + index"
                v-model="involved.nome_social"
                class="form-control"
                type="text"
                placeholder="Nome Social"
            />
            <label :for="'inputNomeSocial-' + index">Nome Social</label>
          </div>
        </div>
      </div>

      <div class="row-fluid mt-3">
        <b>Filiação</b>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-label-group">
            <input
                    :id="'inputMae-' + index"
                    v-model="involved.nomeMae"
                    v-title-case
                    type="text"
                    class="form-control"
                    placeholder="Nome da Mãe"
                    required
            />
            <label :for="'inputMae-' + index">Nome da Mãe</label>
          </div>
        </div>

        <div class="col-12">
          <div class="form-label-group">
            <input
                    :id="'inputPai-' + index"
                    v-model="involved.nomePai"
                    v-title-case
                    type="text"
                    class="form-control"
                    placeholder="Nome do Pai"
                    required
            />
            <label :for="'inputPai-' + index">Nome do Pai</label>
          </div>
        </div>
      </div>

      <div class="row-fluid mt-3">
        <b>Endereço</b>
      </div>
      <div class="row">
        <div class="col-6 col-md-4">
          <div class="form-label-group">
            <input
                    :id="'inputEstado-' + index"
                    v-model="involved.estado"
                    v-title-case
                    type="text"
                    class="form-control"
                    placeholder="Estado"
                    required
            />
            <label :for="'inputEstado-' + index">Estado</label>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="form-label-group">
            <input
                    :id="'inputMunicipio-' + index"
                    v-model="involved.municipio"
                    v-title-case
                    type="text"
                    class="form-control"
                    placeholder="Município"
                    required
            />
            <label :for="'inputMunicipio-' + index">Município</label>
          </div>
        </div>

        <div class="col">
          <div class="form-label-group">
            <input
                    :id="'inputBairro-'+ index"
                    v-model="involved.bairro"
                    v-title-case
                    type="text"
                    class="form-control"
                    placeholder="Bairro"
                    required
            />
            <label :for="'inputBairro-' + index  ">Bairro</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-label-group">
            <input
                    :id="'inputLogradouro-' + index"
                    v-model="involved.logradouro"
                    v-title-case
                    type="text"
                    class="form-control"
                    placeholder="Logradouro"
                    required
            />
            <label :for="'inputLogradouro-' + index">Logradouro</label>
          </div>
        </div>

        <div class="col-6 col-md-3">
          <div class="form-label-group">
            <input
                    :id="'inputNumero-' + index"
                    v-model="involved.numero"
                    type="text"
                    class="form-control"
                    placeholder="Número"
                    required
            />
            <label :for="'inputNumero-' + index">Número</label>
          </div>
        </div>

        <div class="col-6 col-md-3">
          <div class="form-label-group">
            <input
                    :id="'inputComplemento-' + index "
                    v-model="involved.complemento"
                    v-title-case
                    type="text"
                    class="form-control"
                    placeholder="Complemento"
                    required
            />
            <label :for="'inputComplemento-' + index ">Complemento</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-label-group">
            <input
                    :id="'inputReferencia-' + index"
                    v-model="involved.referencia"
                    v-title-case
                    type="text"
                    class="form-control"
                    placeholder="Referência"
                    required
            />
            <label :for="'inputReferencia-' + index">Referência</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <button @click.prevent="addInvolved" class="btn mx-auto btn-primary">+ Adicionar Indivíduos</button>
    </div>

  </fieldset>
</template>

<script>
  import {mapMultiRowFields} from "vuex-map-fields";
  import {required} from 'vuelidate/lib/validators';
  import dayjs from 'dayjs';
  import {titleCase} from "../../_helpers";

  import customParseFormat from "dayjs/plugin/customParseFormat";
  import * as natures from "../../assets/json/natures";
  dayjs.extend(customParseFormat);

  export default {

    data(){
      return{
        typingInvolved: null
      }
    },
    computed: {
      ...mapMultiRowFields("occurrences", ["occurrence.listaEnvolvidos"]),

      user() {
        return this.$store.state.authentication.user;
      },
      involveds() {
        return this.$store.state.occurrences.occurrence.listaEnvolvidos;
      },
      involvedSearch() {
        return this.$store.state.citizens.results;
      },
      complete() {
        return !this.$v.$invalid;
      },
      occurrenceFinished(){
        return this.$store.state.occurrences.occurrence.status === 'FI';
      }
    },
    validations: {
      listaEnvolvidos: {
        $each: {
          nome: {
            required,
          },
          envolvimento: {
            required
          }
        }
      }
    },
    watch: {
      complete: function (status) {
        this.$store.dispatch('occurrences/completeStep', {
          status: status,
          step: 'involved'
        });
      }
    },
    methods: {
      addInvolved() {
        const { dispatch } = this.$store;
        dispatch("occurrences/addEmptyInvolved");
        this.$v.$touch();
      },
      removeInvolved(index) {
        const { dispatch } = this.$store;
        dispatch("occurrences/removeInvolved", index);
      },
      updateInvolved(data, field, index){
        if(!data) return;

        if(this.involveds[index][field] === undefined){
          this.$store.dispatch('occurrences/updateField', {
            path: 'occurrence.listaEnvolvidos[' + index + '].' + field,
            value: data
          });
        }      

        this.listaEnvolvidos[index][field] = data;
        this.$v.listaEnvolvidos.$each.$iter[index].$touch();
      },
      onBlurSearch(index) {
        this.updateInvolved(titleCase(this.typingInvolved), 'nome', index);
        this.$store.dispatch('occurrences/saveOccurrence');
        return false;
      },
      async selectCitizen(selected) {
        if(!selected && this.involvedSearch[0] && this.involvedSearch[0].nome){
          selected = this.involvedSearch[0].nome;
        }
        let index = this.listaEnvolvidos.findIndex(involved => involved && involved.nome === selected);

        if(!this.involvedSearch || !this.involvedSearch.find(result => result.id === selected)) {
          this.updateInvolved(selected, 'nome', index);
          await this.$store.dispatch('occurrences/saveOccurrence');
          return;
        }

        let result = this.involvedSearch.find(result => result.id === selected);
        this.typingInvolved = result.nome.toLowerCase();

        this.updateInvolved(titleCase(result.nome.toLowerCase()), 'nome', index);
        this.updateInvolved(titleCase(result.nomeMae.toLowerCase()), 'nomeMae', index);
        this.updateInvolved(titleCase(result.nomePai.toLowerCase()), 'nomePai', index);
        this.updateInvolved(titleCase(result.bairro.toLowerCase()), 'bairro', index);
        this.updateInvolved(result.dataNascimento, 'dataNascimento', index);

        await this.$nextTick();
        this.$forceUpdate();

      },
      async fetchOptions (search, index) {
        if(search.length < 2) return;
        this.typingInvolved = search;
        await this.$store.dispatch('citizens/searchCitizens', {nome: search});
      },
      calcIdade(index, event){
        if(this.listaEnvolvidos[index].dataNascimento.length < 10) {
          return;
        }
        if(event.code === 'Tab') {
          return;
        }
        this.listaEnvolvidos[index].idade = dayjs().diff(dayjs(this.listaEnvolvidos[index].dataNascimento, 'DD/MM/YYYY'), 'year').toString()
      },
      titleCase(value) {
        return titleCase(value.toLowerCase())
      }
    },
    created() {
      this.$store.dispatch('occurrences/completeStep', {
        status: !this.$v.$invalid,
        step: 'involved'
      });
      this.$v.$touch();
      this.$store.dispatch('occurrences/getOccurrence', {protocol: this.$route.params.protocol, index: 1});
    }

  };
</script>

<style scoped>
  @import "../../assets/scss/_occurrence.scss";
</style>