<template>
  <section>
    <div id="occurrence">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 col-xl-9 mx-auto my-auto">

            <h2 class="text-light text-center mt-5 mb-4 no-print">
              <button @click="cancel" class="btn btn-primary btn-circle btn-md align-bottom">
                <i class="icon-back"/>
              </button>
              Registrar Ocorrência
            </h2>

            <div class="row justify-content-center align-items-center h-100">
              <div class="f1 w-100">

                <div class="row  no-print">
                  <div class="col">

                    <button v-if="occurrence.status === 'FA'"
                            class="btn btn-link float-right" disabled>
                      Finalizada
                    </button>
                    <button v-else-if="occurrence.status === 'FI'"
                            class="btn btn-success float-right" disabled>
                      Concluída
                    </button>
                    <button v-else-if="valid && !status.sync.saving && !status.sync.error && statusOccurrence !== 'FA'"
                            @click="finishOccurrence"
                            class="btn btn-outline-primary float-right"
                            >
                      Finalizar Atendimento
                    </button>
                    <button v-else @click="saveOccurrence"
                            :disabled="status.sync.synced || status.sync.saving"
                            :class="[{'btn-outline-primary': !status.sync.error, 'btn-outline-danger': status.sync.error}, 'btn border-0 float-right']">
                      <span  v-if="status.sync.synced">Salvo</span>
                      <span v-else-if="status.sync.saving">Salvando...</span>
                      <span v-if="status.sync.error"> <i class="icon-repeat"/> Falha na Conexão </span>
                    </button>

                    <router-link :to="{name: 'preview-occurrence'}" class="btn-light">
                      <div class="float-right btn"> <i class="icon-eye"/> Visualizar</div>
                    </router-link>
                  </div>
                </div>

                <div class="f1-steps no-print">
                  <div class="f1-progress">
                    <div
                      class="f1-progress-line"
                      data-now-value="12.5"
                      data-number-of-steps="4"
                      style="width: 12.5%;"
                    ></div>
                  </div>

                  <router-link :to="{name: 'general-occurrence'}"  v-bind:class="{ 'complete': status.general, 'f1-step text-center': true}">
                    <div class="f1-step-icon">
                      <i class="icon-location"/>
                    </div>
                    <p>Dados Principais</p>
                  </router-link>

                  <router-link :to="{name: 'involved-occurrence'}"  v-bind:class="{ 'complete': status.involved, 'f1-step text-center': true}">
                    <div class="f1-step-icon">
                      <i class="icon-user"/>
                    </div>
                    <p>{{ involdedCount }} Indivíduos</p>
                    
                  </router-link>

                  <router-link :to="{name: 'objects-occurrence'}"  v-bind:class="{ 'complete': status.objects, 'f1-step text-center': true}">
                    <div class="f1-step-icon">
                      <i class="icon-clock"/>
                    </div>
                    <p>Objetos</p>
                  </router-link>

                  <router-link :to="{name: 'narrative-occurrence'}"  v-bind:class="{ 'complete': status.narrative, 'f1-step text-center': true}">
                    <div class="f1-step-icon">
                      <i class="icon-bubble"/>
                    </div>
                    <p>Narrativa</p>
                  </router-link>
                </div>

                <fieldset>
                  <router-view/>
                </fieldset>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    occurrence() {
      return this.$store.state.occurrences.occurrence;
    },
    status() {
      return this.$store.state.occurrences.status;
    },
    valid() {
      return this.status.general
              && this.status.involved
              && this.status.objects
              && this.status.narrative;
    },
    involdedCount() {
      try{
        return this.$store.state.occurrences.occurrence.listaEnvolvidos.length;
      }catch (e) {
        return 0;
      }
    }
  },
  methods: {
    cancel() {
      this.$router.replace({ name: "home" });
    },
    saveOccurrence() {
      this.$store.dispatch("occurrences/saveOccurrence");
    },
    finishOccurrence(){
      this.$store.dispatch('occurrences/finishOccurrence');
      this.$router.push({name: 'preview-occurrence'});
    }
  },

  created() {

  }
};
</script>
