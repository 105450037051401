import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import VueTheMask from 'vue-the-mask'
import { titleCase } from './_helpers';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
/*
Sentry.init({
    dsn: 'https://de9806673ebb4fd49c62fc40d297d1cd@sentry.io/1889080',
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
});
*/

import { router } from './_helpers';
import { store } from './_store';

import dayjs from "dayjs";

Vue.config.devtools = process.env.NODE_ENV === 'development';

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.component('v-select', vSelect);
Vue.use(VueTheMask);


Vue.directive('titleCase', function(el, ok){
    let inputEvent = document.createEvent('Event');
    inputEvent.initEvent('input', true, true);

    if(el.value === undefined){
        el = el.getElementsByTagName("input")[0];
        if(el === undefined) return
    }

    let upper = titleCase(el.value)

    if(el.value !== upper){
        el.value = upper;
        el.dispatchEvent(inputEvent)
    }
});


Vue.directive('upperCase', function(el){
    let inputEvent = document.createEvent('Event');
    inputEvent.initEvent('input', true, true);

    let upper = el.value.toUpperCase();

    if(el.value !== upper){
        el.value = upper;
        el.dispatchEvent(inputEvent)
    }
});

Vue.directive('upperFirst', function(el){
    let inputEvent = document.createEvent('Event');
    inputEvent.initEvent('input', true, true);

    let upper = el.value.charAt(0).toUpperCase() + el.value.slice(1)

    if(el.value !== upper){
        el.value = upper;
        el.dispatchEvent(inputEvent)
    }
});

Vue.filter('dateTime', function (value) {
    let format = 'DD/MM/YYYY HH:mm:ss';
    const offset = new Date().getTimezoneOffset();
    if(offset !== 300){
        format += ' Z';
    }
    return dayjs(value).format(format);
});

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});


