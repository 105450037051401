import Vue from 'vue';
import Router from 'vue-router';

import Home from '../components/Home'
import Login from '../components/Login'
import Occurrence from '../components/occurrence/Index'

import GeneralOccurence from '../components/occurrence/General'
import Preview from '../components/occurrence/Preview'
import InvolvedOccurrence from '../components/occurrence/Involved'
import ObjectsOccurrence from '../components/occurrence/Objects'
import NarrativeOccurrence from '../components/occurrence/Narrative'

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes: [
        { path: '/ocorrencias/:limit?', name: 'home', component: Home, alias: '/' },
        { path: '/login', name: 'login', component: Login },
        { path: '/ocorrencia/:protocol', name: 'occurrence', component: Occurrence,
            children: [
                {
                    name: 'general-occurrence',
                    path: 'dados-principais',
                    component: GeneralOccurence
                },
                {
                    name: 'involved-occurrence',
                    path: 'individuos',
                    component: InvolvedOccurrence
                },
                {
                    name: 'objects-occurrence',
                    path: 'objetos',
                    component: ObjectsOccurrence
                },
                {
                    name: 'narrative-occurrence',
                    path: 'narrativa',
                    component: NarrativeOccurrence
                },
                {
                    name: 'preview-occurrence',
                    path: 'visualizar',
                    component: Preview
                },
            ]
        },


        // otherwise redirect to home
        { path: '*', redirect: '/' }
    ]
});


router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const user = JSON.parse(localStorage.getItem('user'));

    let expiration;
    try {
        expiration = user['tokenData'].exp;
    }catch (e) {
        expiration = 0
    }

    if (authRequired && (!user || expiration < Date.now() / 1000)) {
        return next('/login');
    }
    next();
});