const addressSchema = {
    logradouro: '',
    numero: '',
    bairro: '',
    complemento: '',
    referencia: '',
    estado: '',
    municipio: ''
};

module.exports = addressSchema;