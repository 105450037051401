import { citizensService } from '../_services';
import {titleCase} from "../_helpers";

export const citizens = {
    namespaced: true,
    state: {
        results: [],
        controller: new AbortController(),
        loading: false,
    },
    actions: {
        searchCitizens({ commit }, filters) {
            commit('scheduleCitizensRequest', filters);
        },
    },
    mutations: {
        scheduleCitizensRequest(state, filters) {
            if(state.loading){
                state.controller.abort();
                state.controller = new AbortController();
                state.loading = false;
            }

            if (this.schedule) {
                clearTimeout(this.schedule);
            }
            this.schedule = setTimeout( () => {
                this.commit('citizens/searchCitizensRequest', filters);
            }, 10);
        },
        searchCitizensRequest(state, filters) {
            state.loading = true;
            citizensService.searchCitizens(filters, state.controller)
                .then(
                    results => this.commit('citizens/searchCitizensSuccess', results),
                    error => this.commit('citizens/searchCitizensFailure', error)
                )
        },
        searchCitizensSuccess(state, citizens) {
            if(!citizens) return;
            state.results = citizens.map(function(a) {
                a.nome = titleCase(a.nome);
                return a;
            });

            state.results = citizens;
        },
        searchCitizensFailure(state, error) {
            state.status = { error };
        },
    }
};