import { render, staticRenderFns } from "./Involved.vue?vue&type=template&id=66f68379&scoped=true&"
import script from "./Involved.vue?vue&type=script&lang=js&"
export * from "./Involved.vue?vue&type=script&lang=js&"
import style0 from "./Involved.vue?vue&type=style&index=0&id=66f68379&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66f68379",
  null
  
)

export default component.exports