<template>

    <div id="login">
        <div class="container">
        <div class="row">
            <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div class="card card-signin my-5">
                    <div class="card-body">

                        <div class="row-fluid text-center">
                            <img id="shield" height="200" width="100" src="../assets/img/brasao-pmac2.png">
                        </div>

                        <div class="card-title text-center">
                            <h6  class="text-center">Boletins</h6>
                            <h6>Polícia Militar do Acre</h6>
                        </div>
                        <form @submit.prevent="handleSubmit" class="form-signin">
                            <div class="form-label-group">
                                <input type="text" v-model="username" id="inputEmail" class="form-control" placeholder="Email Funcional" required
                                       autofocus>
                                <label for="inputEmail">Email Funcional</label>

                                <div v-show="submitted && !username" class="invalid-feedback">Informe o usuário</div>
                            </div>

                            <div class="form-label-group">
                                <input type="password" v-model="password" id="inputPassword" class="form-control" placeholder="Senha" required>
                                <label for="inputPassword">Senha</label>
                                <div v-show="submitted && !password" class="invalid-feedback">Informe a senha</div>
                            </div>

                            <button :class="{'btn-primary': !loggingIn }" class="btn btn-lg btn-block text-uppercase">
                                <span v-show="!loggingIn">Acessar Boletins</span>
                                <img v-show="loggingIn" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>


<script>
    export default {
        data () {
            return {
                username: '',
                password: '',
                submitted: false
            }
        },
        computed: {
            loggingIn () {
                return this.$store.state.authentication.status.loggingIn;
            }
        },
        created () {
            // reset login status
            this.$store.dispatch('authentication/logout');
        },
        methods: {
            handleSubmit () {
                this.submitted = true;
                const { username, password } = this;
                const { dispatch } = this.$store;
                if (username && password) {
                    dispatch('authentication/login', { username, password});
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../assets/scss/login";
</style>