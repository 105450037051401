<template>
    <div id="modal">
        <div id="preview" class="col-12 col-sm-10 bg-white border-0">
            <div id="background" class="no-print">
                <span v-for="i in 1000" v-bind:key="i">    {{ user.cpf }}    </span>
            </div>
            <div id="content-print">
                <small>
                    <span class="text-muted">{{ user.username }} em {{ new Date() | dateTime  }}</span>
                </small>
                <div class="row no-print">
                    <div class="col align-self-center">
                        <div v-if="occurrence.status === 'FI' || occurrence.status === 'FA'">
                            <a href="#" class="btn btn-primary mt-1" @click="print"><i class="icon-print"/> Imprimir PDF</a>
                        </div>
                    </div>

                    <div v-if="links && links.length > 1" class="col align-self-center text-center">
                        <span class="text-muted small">Outros Registros</span>
                        <ul class="pagination justify-content-center">
                            <li  v-for="(link, index) in links" v-bind:key="index" :class="[{disabled: index + 1 === current}, 'page-item']" >
                                <a class="page-link" @click.prevent="change(index + 1)" :href="link" tabindex="-1">{{ index + 1 }}</a>
                            </li>
                        </ul>

                    </div>

                    <div class="col-4 align-self-center text-right">
                        <a href="#" class="btn btn-link close" @click="close">&times;</a>
                    </div>
                </div>

                <div class="row-fluid">
                    <div class="col text-center">
                        <img height="100px" src="../../assets/img/brasao-pmac.png"/>
                    </div>
                </div>
                <div class="row-fluid text-center">
                    Estado do Acre<br/>
                    Polícia Militar<br/>
                    Boletim de Ocorrência <span v-if="occurrence.tipoBoletim">- {{ occurrence.tipoBoletim }}</span>
                </div>

                <hr>

                <h5 class="mt-4">&bull; Dados da Ocorrência</h5>

                <div class="row border m-0">
                    <div class="col">
                        <table class="table table-sm table-borderless table-responsive">
                            <tr>
                                <td><b>Protocolo:</b></td>
                                <td class="text-left">{{ occurrence.protocolo }}</td>
                            </tr>

                            <tr>
                                <td><b>Data do Registro: </b></td>
                                <td> {{ occurrence.dataRegistro }} </td>
                            </tr>

                            <tr>
                                <td><b>Data do Fato: </b></td>
                                <td> {{ occurrence.dataHoraFato }} </td>
                            </tr>

                            <tr>
                                <td><b>Natureza(s): </b></td>
                                <td><span v-if="occurrence.listaNaturezas">{{ occurrence.listaNaturezas.join(',') }}</span></td>
                            </tr>
                            <tr>
                                <td><b>Endereço: </b></td>
                                <td>
                                    <span v-if="occurrence.bairro">Bairro </span>{{ occurrence.bairro }}
                                    <span v-if="occurrence.logradouro">, </span>{{ occurrence.logradouro }}
                                    <span v-if="occurrence.numero">, Nº </span>{{ occurrence.numero }}
                                    <span v-if="occurrence.complemento">, </span>{{ occurrence.complemento }}
                                    <span v-if="occurrence.referencia">, </span>{{ occurrence.referencia }}
                                </td>
                            </tr>

                            <tr>
                                <td><b>Tipo Local: </b></td>
                                <td>
                                    <span v-if="occurrence.tipoLocal">{{ occurrence.tipoLocal }}</span>
                                </td>

                                <td><b>Tipo Via: </b></td>
                                <td>
                                    <span v-if="occurrence.tipoVia == 'VP'">Via Pública</span>
                                    <span v-else-if="occurrence.tipoVia == 'RE'">Rodovia Estadual</span>
                                    <span v-else-if="occurrence.tipoVia == 'RF'">Rodovia Federal</span>
                                    <span v-else>{{ occurrence.tipoVia }}</span>                
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <h5 class="mt-4 mb-3">&bull; Envolvidos</h5>
                <div class="row-fluid border  p-2" v-for="(involved, index) in occurrence.listaEnvolvidos" v-bind:key="involved.id">
                    <b>Envolvido {{ index + 1}} - {{ involved.envolvimento }}</b>
                    <table class="table table-sm table-borderless table-responsive">
                        <tr>
                            <td><b>Nome Completo</b></td>
                            <td>{{ involved.nome }}</td>

                            <td><b>Data de Nascimento</b></td>
                            <td>{{ involved.dataNascimento }} <span v-if="involved.idade">({{ involved.idade }} anos)</span></td>
                        </tr>
                        <tr>
                            <td><b>CPF</b></td>
                            <td v-if="involved.cpf">{{ involved.cpf }}</td>
                            <td v-else>Não Informado</td>

                            <td><b>RG</b></td>
                            <td v-if="involved.rg">{{ involved.rg}} {{ involved.rg_emissor}}</td>
                            <td v-else>Não Informado</td>
                        </tr>
                        <tr>
                            <td><b>Nome da Mãe</b></td>
                            <td v-if="involved.nomeMae">{{ involved.nomeMae }}</td>
                            <td v-else>Não Informado</td>
                        </tr>
                        <tr>
                            <td><b>Nome do Pai</b></td>
                            <td v-if="involved.nomePai">{{ involved.nomePai }}</td>
                            <td v-else>Não Informado</td>
                        </tr>
                        <tr>
                            <td><b>Endereço: </b></td>
                            <td>
                                <span v-if="involved.bairro">Bairro </span>{{ involved.bairro }}
                                <span v-if="involved.logradouro">, </span>{{ involved.logradouro }}
                                <span v-if="involved.numero">, Nº </span>{{ involved.numero }}
                                <span v-if="involved.complemento">, </span>{{ involved.complemento }}
                                <span v-if="involved.referencia">, </span>{{ involved.referencia }}
                            </td>
                        </tr>
                    </table>
                </div>

                <h5 class="mt-4 mb-3">&bull; Objetos</h5>
                <div class="row-fluid border  p-2" v-for="(object, index) in occurrence.listaArmasObjetos" v-bind:key="index">
                    <b>Objeto {{ index + 1}}</b>
                    <table class="table table-sm table-borderless table-responsive">
                        <tr>
                            <td><b>Tipo</b></td>
                            <td>{{ object.tipo }}</td>

                            <td><b>Descrição</b></td>
                            <td>{{ object.descricao }}</td>
                        </tr>
                    </table>
                </div>

                <div v-if="occurrence.narrativa" class="no-print">
                    <h5 class="mt-4">&bull; Narrativa Atendente</h5>
                    <div  class="narrative row-fluid border p-4">
                        {{ occurrence.narrativa }}
                    </div>
                </div>

                <div v-if="occurrence.narrativaPolicial && occurrence.narrativaPolicial !== occurrence.narrativa">
                    <h5 class="mt-4">&bull; Narrativa Atividades</h5>
                    <div class="narrative row-fluid border p-4">
                        {{ occurrence.narrativaPolicial  }}
                    </div>
                </div>

                <div v-if="occurrence.listaDespachos">
                    <h5 class="mt-4 mb-3">&bull; Guarnição</h5>
                    <div class="row-fluid border p-2" v-for="(despacho, indexDespacho) in occurrence.listaDespachos" v-bind:key="indexDespacho">
                        <b>{{ despacho.equipe.nome }}</b><br>
                        <table v-if="despacho.equipe.listaPessoas" class="table table-sm table-borderless table-responsive">
                            <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Funcão</th>
                                <th>Matrícula</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(policial, indexEquipe) in despacho.equipe.listaPessoas" v-bind:key="indexEquipe">
                                <td>{{ policial.nome }}</td>
                                <td><b>{{ policial.funcao }}</b></td>
                                <td>{{ policial.matricula }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <h5 class="mt-5 mb-3">&bull; Assinaturas</h5>

                <div class="row text-center mt-5">
                    <div class="col mx-4 border-top">
                        Comandante
                    </div>

                    <div class="col mx-4 border-top">
                        Testemunha
                    </div>
                </div>

                <div v-if="occurrence.nomeRecebedor" class="mt-5 pb-5 row justify-content-center text-center">
                    <div class="col-5 mx-5 border-top">
                        {{ occurrence.nomeRecebedor }} - {{ occurrence.unidadeEntrega }}
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import dayjs from "dayjs";

    export default {
        computed: {
            user() {
                return this.$store.state.authentication.user;
            },
            military(){
                return /pm/i.test(this.user.organ);
            },
            occurrence() {
                return this.$store.state.occurrences.occurrence;
            },
            links() {
                return this.$store.state.occurrences.links;
            },
            current() {
                return this.$store.state.occurrences.current;
            },
            forwarder() {
                if(!this.military){
                    return false;
                }
                if(this.occurrence.cpfPolicialRegistro === this.user.cpf){
                    return false;
                }
                return /despachante/i.test(this.user.roles);
            }
        },
        methods: {
            print() {
                window.print();
            },
            change(index){
                this.$store.dispatch('occurrences/getOccurrence', {protocol: this.occurrence.protocolo, index});
                this.$forceUpdate();
            },
            close() {
                if(this.forwarder || !this.occurrence.cpfPolicialRegistro || this.occurrence.status === 'FI' || this.occurrence.status === 'FA'){
                    this.$router.push({ path: 'home' });
                    return;
                }
                this.$router.replace({ name: "general-occurrence" });
            }
        },
        created() {
            this.$store.dispatch('occurrences/getOccurrence', {protocol: this.$route.params.protocol, index: 1});
        },
    }
</script>
<style lang="scss">
    @import "../../assets/scss/preview";
</style>