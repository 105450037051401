import { authHeader } from '../_helpers';
import { config } from '../config';
import dayjs from 'dayjs';
let utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export const occurrencesService = {
    searchOccurrences,
    getOccurrences,
    getOccurrence,
    saveOccurrence
};

function getOccurrences({type, limit}) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = `${config.apiBoletimUrl}/occurrences/timeline?limit=${limit}&type=${type}`;
    return fetch(url, requestOptions).then(handleResponse);
}

function getOccurrence({protocol, index}) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${config.apiBoletimUrl}/occurrence/${protocol}/${index}`, requestOptions)
        .then(handleResponse)
        .then(handleOccurrence)
}

function saveOccurrence(occurrence, controller) {

    let data = Object.assign({}, occurrence);

    data.dataRegistro = dayjs(occurrence.dataRegistro, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm-05:00');
    data.dataHoraFato = dayjs(occurrence.dataHoraFato, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm-05:00');

    const requestOptions = {
        method: "POST",
        signal: controller.signal,
        headers: { 'Content-Type': 'application/json',  ...authHeader() },
        body: JSON.stringify(data),
    };

    if(!occurrence.protocolo) return;

    return fetch(`${config.apiBoletimUrl}/occurrence/${occurrence.protocolo}/save`, requestOptions)
        .then(handleResponse);
}


function searchOccurrences({query, controller}) {
    const requestOptions = {
        method: 'GET',
        signal: controller.signal,
        headers: authHeader()
    };
    let url = `${config.apiBoletimUrl}/occurrences/search?query=${query}`;
    return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const content = text && JSON.parse(text);
        if (!response.ok) {
            const error = (content && content.message) || response.statusText;
            return Promise.reject(error);
        }
        return content
    });
}

function handleOccurrence(content) {
    try{
        if(!content.data.cpfPolicialRegistro){
            content.data.cpfPolicialRegistro = null;
        }
        if(!content.data.narrativaPolicial){
            content.data.narrativaPolicial = content.data.narrativa
        }

        content.data.dataRegistro = dayjs(content.data.dataRegistro).utcOffset(-300).format('DD/MM/YYYY HH:mm');
        content.data.dataHoraFato = dayjs(content.data.dataHoraFato).utcOffset(-300).format('DD/MM/YYYY HH:mm');

    }catch (e) {
        // eslint-disable-next-line no-console
        throw e
    }
    return content
}