import Vue from 'vue';
import Vuex from 'vuex';

import { authentication } from './authentication.module';
import { occurrences } from './occurrences.module';
import { citizens } from './citizens.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        authentication,
        occurrences,
        citizens
    }
});