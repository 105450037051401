const addressSchema = require("./address.schema");

const involvedSchema = {
    _id: '',
    envolvimento: '',
    nome: '',
    sexo: '',
    cpf: '',
    rg: '',
    rg_emissor: '',
    nomeMae: '',
    nomePai: '',
    dataNascimento: '',
    idade: '',
    condicoes_apresentacao: '',

    nome_social: '',
    identidade_genero: '',
    orientacao_sexual: '',

    ... addressSchema,
};

module.exports = involvedSchema;