<template>
  <fieldset  :disabled="occurrenceFinished"  style="display: block">
    <div v-for="(object, index) in listaArmasObjetos" v-bind:key="index">
      <div class="row-fluid mb-2 mt-4">
        <h4>
          Objeto {{ index + 1 }}
          <button
                  @click.prevent="removeObject(index)"
                  type="button"
                  class="close float-none"
                  aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </h4>
      </div>

      <div class="row mb-3">
        <div class="col-12 col-md-4">
          <div class="form-label-group">
            <input
                    :id="'inputTipo-' + index"
                    v-title-case
                    v-model="object.tipo"
                    @input="updateObject($event.target.value, 'tipo', index)"
                    :class="{ 'is-invalid ': $v.listaArmasObjetos.$each.$iter[index].tipo.$error, 'style-chooser': true }"
                    type="text"
                    class="form-control"
                    placeholder="Tipo"
                    required
            />
            <label :for="'inputTipo-' + index">Tipo</label>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 col-md-4">

            <textarea
                    :id="'inputDescricao-' + index"
                    v-model="object.descricao"
                    v-upper-first
                    @input="updateObject($event.target.value, 'descricao', index)"
                    :class="{ 'is-invalid ': $v.listaArmasObjetos.$each.$iter[index].descricao.$error, 'style-chooser': true }"
                    type="text"
                    class="form-control"
                    placeholder="Descrição"
                    required
            />
        </div>
      </div>

    </div>

    <div class="row">
      <button @click.prevent="addObject" class="btn mx-auto btn-primary">+ Adicionar Objeto</button>
    </div>
  </fieldset>
</template>

<script>
  import {mapMultiRowFields} from "vuex-map-fields";
  import {required} from 'vuelidate/lib/validators';

  export default {
    computed: {
      ...mapMultiRowFields("occurrences", ["occurrence.listaArmasObjetos"]),

      user() {
        return this.$store.state.authentication.user;
      },
      complete() {
        return !this.$v.$invalid;
      },
      occurrenceFinished(){
        return this.$store.state.occurrences.occurrence.status === 'FI';
      }
    },
    validations: {
      listaArmasObjetos: {
        $each: {
          tipo: {
            required,
          },
          descricao: {
            required,
          }
        }
      }
    },
    watch: {
      complete: function (status) {
        this.$store.dispatch('occurrences/completeStep', {
          status: status,
          step: 'objects'
        });
      }
    },
    methods: {
      addObject() {
        const { dispatch } = this.$store;
        dispatch("occurrences/addEmptyObject");
        this.$v.$touch();
      },
      removeObject(index) {
        const { dispatch } = this.$store;
        dispatch("occurrences/removeObject", index);
      },
      updateObject(data, field, index){
        this.listaArmasObjetos[index][field] = data;
        this.$v.listaArmasObjetos.$each.$iter[index].$touch()
      },
    },
    created() {
      this.$v.$touch();
      this.$store.dispatch('occurrences/completeStep', {
        status: !this.$v.$invalid,
        step: 'objects'
      });
      this.$store.dispatch('occurrences/getOccurrence', {protocol: this.$route.params.protocol, index: 1});
    }

  };
</script>