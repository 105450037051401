export function normalize(spy){
    for(let key of Object.keys(spy)){
        if(spy.hasOwnProperty(key)){
            if(typeof spy[key] === "object" && spy[key] !== null){
                spy[key] = normalize(spy[key])
            }else{
                spy[key] = '';
            }
        }
    }
    return spy;
}