<template>
  <fieldset  :disabled="occurrenceFinished"   style="display: block">
    <div class="row">
      <div class="col">
        <div class="form-label-group">
          <textarea v-model="narrativaPolicial" v-upper-first class="form-control" id="textareaNarrativa" rows="20"/>
          <label for="textareaNarrativa">Narrativa</label>
        </div>
      </div>
    </div>

  </fieldset>
</template>
<script>
  import { mapFields } from 'vuex-map-fields';
  import { required } from 'vuelidate/lib/validators'

  export default {
    computed: {
      ...mapFields('occurrences', [
        'occurrence.narrativaPolicial',
        'occurrence.unidadeEntrega',
      ]),
      occurrenceFinished(){
        return this.$store.state.occurrences.occurrence.status === 'FI';
      },
      complete() {
        return !this.$v.$invalid;
      },
    },
    validations: {
      narrativaPolicial: {
        required,
      }
    },
    watch: {
      complete: function (status) {
        this.$store.dispatch('occurrences/completeStep', {
          status: status,
          step: 'narrative'
        });
      }
    },
    created() {
      this.$store.dispatch('occurrences/completeStep', {
        status: this.complete,
        step: 'narrative'
      });
      this.$store.dispatch('occurrences/getOccurrence', {protocol: this.$route.params.protocol, index: 1});
      this.$v.$touch();
    }
  };

</script>