module.exports = {
    config: {
        //apiEsfingeUrl: 'https://esfinge.pmac.ac.gov.br',
        apiEsfingeUrl: 'https://esfinge.acre.seg.br',
        apiBoletimUrl: 'https://bo.acre.seg.br/api',
        //apiBoletimUrl: 'https://bo.pmac.ac.gov.br/api',
        //apiBoletimUrl: 'http://localhost:4000/api',
        apiOraculoUrl: 'https://bo.acre.seg.br/oraculo',
    }
};
