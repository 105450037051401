const objectSchema = require("./object.schema");
const involvedSchema = require("./involved.schema");

const OccurrenceSchema = {
    uuid: String,
    despachoRegistro: String,
    cpfPolicialRegistro: String,
    dataPolicialRegistro: Date,
    status: String,
    protocolo: String,
    dataRegistro: Date,
    dataHoraFato: Date,
    logradouro: String,
    numero: String,
    bairro: String,
    complemento: String,
    referencia: String,
    codIBGEMunicipio: String,
    listaNaturezas: [],
    tipoLocal: String,
    tipoVia: String,
    tipoBoletim: String,
    listaEnvolvidos: [
        involvedSchema
    ],
    listaDespachos: [{
        equipe: {
            nome: String,
            listaPessoas: [{
                cpf: String,
                nome: String,
                funcao: String,
                matricula: String
            }]
        }
    }],
    listaArmasObjetos: [
        objectSchema
    ],
    narrativa: String,
    narrativaPolicial: String,
    unidadeEntrega: String,
    nomeRecebedor: String,
};

module.exports = OccurrenceSchema;