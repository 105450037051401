/**
 * @return {string}
 */
export function titleCase(value) {
    if(!value) return '';

    let titleCase = value.replace(/([^\s]{2,})/g, function(a) {
        if(/^[A-Z]/.test(a)){
            return a;
        }
        if(/^(da|de|do|das|dos|ao|a|o|e|na|no)$/i.test(a)){
            return a.toLowerCase();
        }
        return a.charAt(0).toUpperCase() + a.slice(1)
    });
    titleCase = titleCase.charAt(0).toUpperCase() + titleCase.slice(1);

    return titleCase
}