<template>
  <section>
    <main id="home">
      <div class="container">
        <div class="row h-100">
          <div class="col my-auto">
            <h2 class="text-center text-white">

              <router-link :to="{ name: 'login' }">
                <button class="btn btn-primary btn-circle btn-md align-bottom">
                  <i class="icon-logout"/>
                </button>
              </router-link>
              Papyrus</h2>


                <h5 class="text-center text-white pt-4">Registro de Ocorrências Desativado</h5>
                <p class="text-center text-white pb-4">
                Registros de ocorrências deverão ser feitos através do <b><u><a href="https://seguranca.sinesp.gov.br" target="_blank">Sinesp PPE</a></u></b><br>
                Você continuará podendo usar o Papyrus para consulta.
                </p>
              
              

            <div class="row justify-content-center align-items-center  mt-2 mt-sm-0 mb-4">
              <div class="col-12 col-md-8">
                <v-select
                        :components="{OpenIndicator}"
                        class="style-chooser"
                        placeholder="Em Destaque"
                        :value="query"
                        v-title-case
                        @search="search"
                        @input="search"
                        filterable="false"
                        :options="[
                                {label: 'Em Destaque', type: 'featured'},
                                {label: 'Últimos Registros', type: 'latest'},
                                {label: 'Minhas Ocorrências', type: 'my'}
                               ]"
                        :reduce="option => option.type || option.label"
                />

              </div>


            </div>

            <div  v-if="typeDisplay === 'widgets'"  class="occurrences row justify-content-center align-items-center h-100  my-auto">


              <div v-show="!occurrences.items" v-for="(item,index) in Array(limit)" :key="index"  class="col-12 col-sm-4">
                  <div class="profile-card card shadow">
                  </div>
              </div>



              <div class="col-12 col-sm-4" v-for="item in occurrences.items" :key="item._id" >
                <div class="profile-card card shadow">
                  <div class="protocol">
                    <small class="text-right text-muted" v-html="$options.filters.protocol(item.protocolo)"/>
                  </div>

                  <div class="picture text-center">
                      <img
                              :title="getTitlePrority(item.prioridade)"
                              :style="'background-color:' + getColorPrority(item.prioridade)"
                              :src="'/naturezas/' + getIcon(item.listaNaturezas)"
                              @error="e => e.target.src = '/naturezas/padrao.png'"
                      />
                  </div>

                  <div class="card-body">
                    <div class="content">
                      <h2 class="text-dark h5 font-weight-bold mb-0 pb-0">{{ item.listaNaturezas ? item.listaNaturezas.join(',') : "Sem Classifiçacão" }}</h2>
                      <small v-if="item.status === 'FI'" class="d-md-block"><b>Finalizada </b></small>
                      <small v-if="item.reiteracoes > 1">{{ item.reiteracoes }} Reiterações
                      </small>
                      <p class="text-muted text-uppercase font-weight-light">{{ item.logradouro }}, {{ item.numero ? `Nº ${item.numero}` : 'S/N' }}, {{ item.bairro }}</p>
                    </div>
                  </div>

                  <div class="actions">
                    <router-link v-if="item.cpfPolicialRegistro === user.cpf" :to="{ name: 'general-occurrence', params: { protocol: item.protocolo } }">
                      <button class="btn btn-primary btn-circle btn-md align-bottom">
                        <i class="d-none d-sm-block ml-1 icon-pen"/>
                        <span class="d-sm-none">Registrar</span>
                      </button>
                    </router-link>
                    <router-link v-else :to="{ name: 'preview-occurrence', params: { protocol: item.protocolo } }">
                      <button :class="[{'btn-primary': item.cpfOperador === user.cpf},'btn', 'btn-circle', 'btn-md']">
                        <i class="d-none d-sm-block icon-eye"/>
                        <span class="d-sm-none">Visualizar</span>
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        typeDisplay: 'widgets',
        limit: 9,
        query: '',
        type_occurrences: 'featured',
        OpenIndicator: {
          render: createElement => createElement('i', {class: {'icon-search': true}}),
        }
      }
    },

    computed: {
      user() {
        return this.$store.state.authentication.user;
      },
      occurrences() {
        return this.$store.state.occurrences.occurrences;
      },
      typeOccurrences() {
        return this.$store.state.occurrences.type;
      }
    },
    watch:{
      $route (){
        if(this.$route.params.limit){
          this.limit = this.$route.params.limit;
        }
        this.loadOccurrences({auto_refresh: true});
      }
    },
    created() {
      if(this.$route.params.limit){
        this.limit = this.$route.params.limit;
      }
      this.loadOccurrences({auto_refresh: true});
    },
    beforeDestroy() {
      if (this.interval) {
        clearTimeout(this.interval);
      }
    },
    filters: {
      protocol: function(value) {
        if (!value) return "";
        value = value.toString();
        let year = value.slice(0, 4);
        let month = value.slice(4, 6);
        let day = value.slice(6, 8);
        let hour = value.slice(8, 10);
        let minute = value.slice(10, 12);
        let second = value.slice(12, 14);
        let sequence = value.slice(14);
        return `${year}-${month}-${day} <b>${hour}:${minute}:${second}</b> ${sequence}`;
      }
    },
    methods: {
      loadOccurrences({auto_refresh}) {
        if (this.interval) {
          clearTimeout(this.interval);
        }
        this.$store.dispatch("occurrences/getOccurrences", {limit: this.limit, type: this.type_occurrences});

        if(!auto_refresh) return;
        this.interval = window.setInterval(() => {
          this.loadOccurrences({auto_refresh: true});
        }, 5000);

      },
      openOccurence(protocol) {
        this.$router.push({ name: 'preview-occurrence', params: { protocol: protocol } })
      },
      getColorPrority: function(classification) {
        switch (classification) {
          case "AV":
            return "#f34141";
          case "AL":
            return "#f47100";
          case "ME":
            return "#0062E6";
          case "BA":
            return "#24d9b0";
          default:
            return "#24d9b0";
        }
      },
      getTitlePrority: function(classification) {
        switch (classification) {
          case "AV":
            return "Alerta Vermelho";
          case "AL":
            return "Alta";
          case "ME":
            return "Média";
          case "BA":
            return "Baixa";
          default:
            return "Não Classificada";
        }
      },
      getIcon: function (natures) {
        if(!natures || !natures[0]) {
          return 'padrao.png'
        }
        return natures[0]
                .toLowerCase()
                .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                .replace(/\s/g,"-") + '.png';
      },
      search: function (value) {

        if(!value) return
        this.query = value

        if (this.interval) {
          clearTimeout(this.interval);
        }

        if(value === 'featured') {
          this.type_occurrences = value;
          this.loadOccurrences({auto_refresh: true});
          return;
        }

        if(value === 'latest') {
          this.type_occurrences = value;
          this.loadOccurrences({auto_refresh: true});
          return;
        }

        if(value === 'my') {
          this.type_occurrences = value;
          this.loadOccurrences({auto_refresh: false});
          return;
        }

        return this.$store.dispatch("occurrences/searchOccurrences", {query: value});
      }

    }
  };
</script>

<style lang="scss">
  @import "../assets/scss/home";
</style>