import { authHeader } from '../_helpers';
import { config } from '../config';

export const citizensService = {
    searchCitizens
};

function searchCitizens(filters, controller) {
    const requestOptions = {
        method: 'POST',
        signal: controller.signal,
        headers: authHeader(),
        body: JSON.stringify(filters)
    };
    let url = `${config.apiOraculoUrl}/individuos`;
    return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const content = text && JSON.parse(text);
        if (!response.ok) {
            const error = (content && content.message) || response.statusText;
            return Promise.reject(error);
        }
        return content.data;
    });
}
