import { occurrencesService } from '../_services';

import { getField, updateField } from 'vuex-map-fields';
import { normalize } from '../_helpers/scheme-model';

import OccurrenceSchema  from '../../../schemas/occurrence.schema';
import involvedSchema from '../../../schemas/involved.schema';
import objectSchema  from '../../../schemas/object.schema';

export const occurrences = {
    namespaced: true,
    state: {
        type: 'my',
        occurrences: [],
        links: [],
        total: 1,
        current: 1,
        occurrence: JSON.parse(JSON.stringify(normalize(OccurrenceSchema))),
        status: {
            general: false,
            involved: false,
            objects: null,
            narrative: false,
            sync: {
                controller: new AbortController(),
                loading: false,
                saving: false,
                synced: true,
                finished: false,
                error: false,
            },
            search: {
                controller: new AbortController(),
                loading: false,
                error: false,
            },
            load: {}
        },
    },
    actions: {
        updateField({ commit }, payload) {
            commit('updateField', payload);
        },
        getOccurrences({ commit }, {type, limit}) {
            commit('getOccurrencesRequest', {type, limit});
            occurrencesService.getOccurrences({type, limit})
                .then(
                    occurrences => commit('getOccurrencesSuccess', occurrences),
                    error => commit('getOccurrencesFailure', error)
                );
        },
        searchOccurrences({ commit, state }, { query }) {
            commit('searchOccurrencesRequest', {query});
            
            state.status.search.error = false;
        
            if(state.status.search.loading){
                state.status.search.controller.abort();
                state.status.search.controller = new AbortController();
                state.status.search.loading = false;
            }

            let controller = state.status.search.controller

            occurrencesService.searchOccurrences({query, controller})
                .then(
                    occurrences => commit('searchOccurrencesSuccess', occurrences),
                    error => commit('searchOccurrencesFailure', error)
                );
        },
        completeStep({ commit }, step){
            commit('completeStep', step);
        },
        finishOccurrence({ commit, state } ) {
            state.occurrence.status = 'FA';
            state.status.sync.finished = true;
            commit('saveOccurrenceRequest');

        },
        getOccurrence({ commit, state }, { protocol, index }) {
            state.current = index;
            commit('getOccurrenceRequest');
            occurrencesService.getOccurrence({protocol, index})
                .then(
                    content => commit('getOccurrenceSuccess', content),
                    error => commit('getOccurrenceFailure', error)
                );
        },
        saveOccurrence({ commit }) {
            commit('saveOccurrenceRequest');
        },
        addEmptyInvolved({ commit }) {
            commit('addEmptyInvolved')
        },
        addEmptyObject({ commit }) {
            commit('addEmptyObject')
        },
        removeInvolved({ commit }, index) {
            commit('removeInvolved', index)
        },
        removeObject({ commit }, index) {
            commit('removeObject', index)
        }
    },
    getters: {
        getField
    },
    mutations: {
        // Vue map fields
        updateField(state, ref) {
            updateField(state, ref);

            if(state.occurrence.status !== 'FI'){
                state.occurrence.status = 'DE';
                state.status.finished = false;
            }

            state.status.sync.synced = false;
            state.status.sync.error = false;
            state.status.sync.saving = true;

            if(state.status.sync.loading){
                state.status.sync.controller.abort();
                state.status.sync.controller = new AbortController();
                state.status.sync.loading = false;
            }

            if (this.schedule) {
                clearTimeout(this.schedule);
            }

            this.schedule = setTimeout( () => {
                this.commit('occurrences/saveOccurrenceRequest');
            }, 1000);
        },

        completeStep(state, {step, status}) {
            state.status[step] = status;
        },

        // Get occurrences
        getOccurrencesRequest(state, {type}) {
            state.type = type;
            Object.assign(state.occurrences,{ loading: true })
        },
        getOccurrencesSuccess(state, content) {
            state.occurrences = { items: content.data };
        },
        getOccurrencesFailure(state, error) {
            state.occurrences = { error };
        },

        // Get a occurrence
        getOccurrenceRequest(state) {
            state.status.load = {
                loading: true
            };
        },
        getOccurrenceSuccess(state, content) {
            state.status.load = {
                loading: false,
                success: true
            };

            state.links = content.links;
            state.total = content.total;

            Object.assign(state.occurrence, content.data);
            
            /*
            state.occurrence.listaEnvolvidos.forEach((involved, index) => {
                let localInvolvedSchema = JSON.parse(JSON.stringify(involvedSchema));
                let mergeFields = Object.assign(localInvolvedSchema, state.occurrence.listaEnvolvidos[index]);
                Object.assign(state.occurrence.listaEnvolvidos[index], mergeFields);
            })
            */
        },
        getOccurrenceFailure(state, error) {
            state.status.load = {
                loading: false,
                success: false,
                error: error
            };
        },

        // Get occurrences
        searchOccurrencesRequest(state, {type}) {
            state.type = type;
            state.status.search.loading = true;
            Object.assign(state.occurrences,{ loading: true })
        },
        searchOccurrencesSuccess(state, content) {
            state.occurrences = { items: content.data };
        },
        searchOccurrencesFailure(state, error) {
            state.occurrences = { error };
        },

        // Save occurrence
        saveOccurrenceRequest(state) {
            state.status.sync.loading = true;
            state.status.sync.synced = false;

            occurrencesService.saveOccurrence(state.occurrence, state.status.sync.controller)
                .then(
                    response => this.commit('occurrences/saveOccurrenceSuccess', response),
                    error => this.commit('occurrences/saveOccurrenceFailure', error)
                );
        },
        saveOccurrenceSuccess(state, response) {

            state.occurrence.status = response.data.status;

            state.status.sync.loading = false;
            state.status.sync.saving = false;
            state.status.sync.error = false;
            state.status.sync.synced = true;
        },
        saveOccurrenceFailure(state, error) {
            state.status.sync.loading = false;
            state.status.sync.saving = false;
            state.status.sync.synced = false;
            state.status.sync.error = error;
        },

        addEmptyInvolved(state) {
            state.occurrence.listaEnvolvidos.push(JSON.parse(JSON.stringify(involvedSchema)))
        },
        removeInvolved(state, index) {
            state.occurrence.listaEnvolvidos.splice(index, 1)
        },
        addEmptyObject(state) {
            state.occurrence.listaArmasObjetos.push(JSON.parse(JSON.stringify(objectSchema)))
        },
        removeObject(state, index) {
            state.occurrence.listaArmasObjetos.splice(index, 1)
        }
    }
};